import { render, staticRenderFns } from "./NoGoodsStock.vue?vue&type=template&id=f0c48112&scoped=true&"
import script from "./NoGoodsStock.vue?vue&type=script&lang=js&"
export * from "./NoGoodsStock.vue?vue&type=script&lang=js&"
import style0 from "./NoGoodsStock.vue?vue&type=style&index=0&id=f0c48112&lang=less&scoped=true&"
import style1 from "./NoGoodsStock.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0c48112",
  null
  
)

export default component.exports